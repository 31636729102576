<template>
  <AggStat />
</template>

<script>
import AggStat from '../../components/stat/AggStat'

export default {
  name: 'Stat',
  components: {
    AggStat,
  }
}
</script>