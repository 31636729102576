<template>
  <div>
    <DateFilters
        @update="getDataFromApi"
        ref="dateFilters"
    />

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Минимальная дата заправки</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.date.min" v-if="statData.date.min !== null" />

        <div v-if="statData.date.min === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Максимальная дата заправки</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.date.max" v-if="statData.date.max !== null" />

        <div v-if="statData.date.max === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Минимальный пробег</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.mileage.min" v-if="statData.mileage.min !== null" />

        <div v-if="statData.mileage.min === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Максимальный пробег</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.mileage.max" v-if="statData.mileage.max !== null" />

        <div v-if="statData.mileage.max === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Минимальный объем топлива</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.volume.min" v-if="statData.volume.min !== null" />

        <div v-if="statData.volume.min === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Максимальный объем топлива</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.volume.max" v-if="statData.volume.max !== null" />

        <div v-if="statData.volume.max === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Минимальная цена за литр</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.costPerLiter.min" v-if="statData.costPerLiter.min !== null" />

        <div v-if="statData.costPerLiter.min === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Максимальная цена за литр</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.costPerLiter.max" v-if="statData.costPerLiter.max !== null" />

        <div v-if="statData.costPerLiter.max === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-divider></v-divider>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Минимальная стоимость заправки</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.totalCost.min" v-if="statData.totalCost.min !== null" />

        <div v-if="statData.totalCost.min === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="ma-3 d-inline-block"
        width="450"
        elevation="11"
        outlined
        shaped
        :loading="loading"
    >
      <v-card-title>Максимальная стоимость заправки</v-card-title>

      <v-card-text v-if="!loading">
        <RefuelingItem :refueling="statData.totalCost.max" v-if="statData.totalCost.max !== null" />

        <div v-if="statData.totalCost.max === null">
          Нет данных
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import statApi from '../../api/stat.js'
import RefuelingItem from '../RefuelingItem'
import DateFilters from "../utils/DateFilters";
import moment from "moment";

export default {
  name: 'AggStat',
  data: () => ({
    loading: true,
    statData: {},
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi () {
      this.loading = true

      let
          dateFromIso = null,
          dateToIso = null

      if (this.$refs.dateFilters.dateFrom) {
        dateFromIso = moment(this.$refs.dateFilters.dateFrom).format('YYYY-MM-DDT00:00:00Z')
      }

      if (this.$refs.dateFilters.dateTo) {
        dateToIso = moment(this.$refs.dateFilters.dateTo).format('YYYY-MM-DDT23:59:59Z')
      }

      statApi.getAgg(dateFromIso, dateToIso).then(response => {
        this.statData = response.data.stat
      }).catch(() => {
        this.statData = {}
      }).finally(() => this.loading = false)
    },
  },
  components: {
    RefuelingItem,
    DateFilters,
  }
}
</script>