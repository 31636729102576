<template>
  <div>
    {{ station.name }} (id = {{ station.id }})
  </div>
</template>

<script>
export default {
  name: 'StationItem',
  props: {
    'station': Object,
  },
}
</script>