<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          Дата
        </v-col>
        <v-col>
          {{ refueling.date | fmtDate }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          Станция
        </v-col>
        <v-col>
          <div v-if="refueling.station !== null">
            <StationItem :station="refueling.station" />
          </div>

          <div v-if="refueling.station === null">
            не указано
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          Пробег
        </v-col>
        <v-col>
          {{ this.formatMileage() }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          Заправлено
        </v-col>
        <v-col>
          {{ this.formatVolume() }} л. по {{ this.formatCostPerLiter() }} р. за литр <br />
          на {{ this.formatTotalCost() }} р.
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import StationItem from '../components/StationItem'

export default {
  name: 'RefuelingItem',
  props: {
    'refueling': Object,
  },
  methods: {
    formatMileage() {
      return this.refueling.mileage ? this.refueling.mileage : 'Не указан';
    },
    formatVolume() {
      return this.refueling.volume / 100;
    },
    formatCostPerLiter() {
      return this.refueling.costPerLiter / 100;
    },
    formatTotalCost() {
      return this.refueling.totalCost / 100;
    },
  },
  components: {
    StationItem,
  },
}
</script>